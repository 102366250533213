<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="CONFIGURACION"
      ref="pageForm"
      :items="items"
      :isAdmin="onlyAdminRole"
      @closeModa="typeform = {}"
    >
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" />
      </template>
    </PageHeader>
    <Permissions ref="permissionsC" :role="currentRole" />
    <HierarchyStructure ref="hierarchyStructureC" :role="currentRole" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.search') }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      primary
                      class="shadow-lg"
                    ></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive mb-0">
              <b-table
                :items="getRoles"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="filter"
                :busy="busyPermissions"
                responsive
              >
                <template #cell(actions)="data">
                  <div class="action-btn-container">
                    <b-dropdown
                      variant="link"
                      no-caret
                      class="position-absolute"
                    >
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <div v-if="onlyAdminRole">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">EDITAR</span>
                        </b-dropdown-item>
                      </div>
                      <!-- <div v-if="onlyAdminRole">
                        <b-dropdown-item @click="handlePermissions(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">PERMISOS</span>
                        </b-dropdown-item>
                      </div> -->
                      <!-- <div v-if="onlyAdminRole">
                        <b-dropdown-item
                          @click="handlePermissionsHerarchy(data.item)"
                        >
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">PERMISOS</span>
                        </b-dropdown-item>
                      </div> -->
                      <div v-if="onlyAdminRole">
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="uil-trash m-2 font-size-18" />
                          <span class="align-middle ml-5">ELIMINAR</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header';
import { HierarchyStructure, PageForm, Permissions } from './components';
import Swal from 'sweetalert2';

export default {
  components: { Layout, PageHeader, PageForm, Permissions, HierarchyStructure },
  data() {
    return {
      roles: [],
      title: 'ROLES',
      items: [],
      typeform: {},
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,
      filter: null,
      //
      fields: [
        {
          key: 'name',
          label: 'Nombre',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Acciones',
          sortable: false,
        },
      ],
      currentRole: {},
      busyPermissions: false,
    };
  },
  computed: {
    rows() {
      return this.getRoles.length;
    },
    ...mapGetters({
      getRoles: 'roles/getRoles',
      onlyAdminRole: 'user/onlyAdminRole',
      success: 'roles/getSuccess',
      permissions: 'permissions/getPermissions',
    }),
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    edit(item) {
      this.typeform = { ...item };
      this.$refs.pageForm.modal = true;
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {};
      this.fetchRoles();
    },
    async handlePermissionsHerarchy(item) {
      this.busyPermissions = true;
      await this.findManyPermissionsBy({ roleId: item._id });

      if (this.permissions.length) {
        const formatted = await this.buildNestedPermissions(this.permissions);

        console.log('formatted', formatted);
        this.setHierarchyStructureBase(formatted);
      } else {
        this.resetHierarchyStructureBase();
      }

      this.currentRole = { ...item };
      this.busyPermissions = false;
      this.$refs.hierarchyStructureC.modal = true;
    },
    async remove(item) {
      const { isConfirmed } = await Swal.fire({
        title: 'Eliminar',
        text: '¿Está seguro de eliminar este registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
      });

      if (!isConfirmed) return;

      const { _id: id } = item;

      await this.removeRole({ id });

      if (!this.success)
        return Swal.fire({
          title: 'Error al eliminar',
          text: 'Por favor, intente de nuevo',
          icon: 'error',
        });

      Swal.fire({
        title: 'Eliminado',
        text: 'El registro ha sido eliminado con éxito',
        icon: 'success',
      });

      this.fetchRoles();
    },
    ...mapMutations({
      setHierarchyStructureBase: 'permissions/setHierarchyStructureBase',
      resetHierarchyStructureBase: 'permissions/resetHierarchyStructureBase',
    }),
    ...mapActions({
      fetchRoles: 'roles/fetchRoles',
      findManyPermissionsBy: 'permissions/findManyBy',
      removeRole: 'roles/remove',
      buildNestedPermissions: 'permissions/buildNestedPermissions',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
