<template>
  <b-modal
    id="bv-modal-permissions"
    centered
    v-model="modal"
    size="lg"
    hide-footer
  >
    <Multiselect v-model="value" :options="options" label="module" />
    <div
      v-if="value"
      class="d-flex justify-content-between align-items-center mt-2"
    >
      <vs-checkbox v-model="value.canRead"> LEER </vs-checkbox>
      <vs-checkbox v-model="value.canWrite"> CREAR </vs-checkbox>
      <vs-checkbox v-model="value.canUpdate"> ACTUALIZAR </vs-checkbox>
      <vs-checkbox v-model="value.canDelete"> ELIMINAR </vs-checkbox>
    </div>
    <div>
      <vs-button @click="save" :loading="loading" color="primary" class="mt-3">
        GUARDAR
      </vs-button>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapActions } from 'vuex';

export default {
  name: 'PermissionsSelect',
  components: { Multiselect },
  data() {
    return {
      modal: false,
      value: null,
      options: [
        {
          module: '1',
          canRead: true,
          canWrite: false,
          canUpdate: true,
          canDelete: true,
        },
        {
          module: '2',
          canRead: true,
          canWrite: true,
          canUpdate: false,
          canDelete: true,
        },
        {
          module: '3',
          canRead: true,
          canWrite: true,
          canUpdate: true,
          canDelete: false,
        },
        {
          module: '4',
          canRead: false,
          canWrite: true,
          canUpdate: true,
          canDelete: true,
        },
      ],
      loading: false,
    };
  },
  methods: {
    async save() {
      this.loading = true;

      const { _id, ...rest } = this.value;

      await this.update({
        id: _id,
        data: rest,
      });

      this.loading = false;

      this.modal = false;
      this.value = null;
    },
    ...mapActions({
      update: 'permissions/update',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
