<template>
  <b-modal
    id="bv-modal-permissions-hierarchy"
    centered
    v-model="modal"
    size="lg"
    hide-footer
  >
    <p>
      ROL: <strong>{{ role.name }}</strong>
    </p>
    <div v-for="permission in permissions" :key="permission.id" class="mb-2">
      <b-button v-b-toggle="`collapse-${permission.id}`" variant="primary">
        {{ $t(`modules.${permission.name}`) }}
      </b-button>
      <b-collapse :id="`collapse-${permission.id}`" class="mt-2">
        <b-card>
          <div class="d-flex">
            <div v-if="permission.readView">
              <vs-checkbox
                :value="permission.canRead"
                @change="
                  updateReadValue({
                    id: permission.id,
                    value: !permission.canRead,
                    key: 'canRead',
                  })
                "
                >VER/LEER</vs-checkbox
              >
            </div>
            <div v-if="permission.createView">
              <vs-checkbox
                :value="permission.canWrite"
                @change="
                  updateReadValue({
                    id: permission.id,
                    value: !permission.canWrite,
                    key: 'canWrite',
                  })
                "
                >CREAR</vs-checkbox
              >
            </div>
            <div v-if="permission.updateView">
              <vs-checkbox
                :value="permission.canUpdate"
                @change="
                  updateReadValue({
                    id: permission.id,
                    value: !permission.canUpdate,
                    key: 'canUpdate',
                  })
                "
                >ACTUALZAR</vs-checkbox
              >
            </div>
            <div v-if="permission.deleteView">
              <vs-checkbox
                :value="permission.canDelete"
                @change="
                  updateReadValue({
                    id: permission.id,
                    value: !permission.canDelete,
                    key: 'canDelete',
                  })
                "
                >ELIMINAR</vs-checkbox
              >
            </div>
          </div>

          <div v-if="permission.children">
            <div
              v-for="child in permission.children"
              :key="child.id"
              class="mb-2"
            >
              <b-button v-b-toggle="`collapse-inner-${child.id}`">
                {{ $t(`modules.${child.name}`) }}
              </b-button>
              <b-collapse :id="`collapse-inner-${child.id}`" class="mt-2">
                <b-card>
                  <div class="d-flex">
                    <div v-if="child.readView">
                      <vs-checkbox
                        :value="child.canRead"
                        @change="
                          updateReadValue({
                            id: child.id,
                            value: !child.canRead,
                            key: 'canRead',
                          })
                        "
                        >VER/LEER</vs-checkbox
                      >
                    </div>
                    <div v-if="child.createView">
                      <vs-checkbox
                        :value="child.canWrite"
                        @change="
                          updateReadValue({
                            id: child.id,
                            value: !child.canWrite,
                            key: 'canWrite',
                          })
                        "
                        >CREAR</vs-checkbox
                      >
                    </div>
                    <div v-if="child.updateView">
                      <vs-checkbox
                        :value="child.canUpdate"
                        @change="
                          updateReadValue({
                            id: child.id,
                            value: !child.canUpdate,
                            key: 'canUpdate',
                          })
                        "
                        >ACTUALZAR</vs-checkbox
                      >
                    </div>
                    <div v-if="child.deleteView">
                      <vs-checkbox
                        :value="child.canDelete"
                        @change="
                          updateReadValue({
                            id: child.id,
                            value: !child.canDelete,
                            key: 'canDelete',
                          })
                        "
                        >ELIMINAR</vs-checkbox
                      >
                    </div>
                    <div v-if="child.children">
                      <div v-for="c in child.children" :key="c.id" class="mb-2">
                        <b-button v-b-toggle="`collapse-inner-${c.id}`">
                          {{ $t(`modules.${c.name}`) }}
                        </b-button>

                        <b-collapse :id="`collapse-inner-${c.id}`" class="mt-2">
                          <b-card>
                            <div class="d-flex">
                              <div v-if="c.readView">
                                <vs-checkbox
                                  :value="c.canRead"
                                  @change="
                                    updateReadValue({
                                      id: c.id,
                                      value: !c.canRead,
                                      key: 'canRead',
                                    })
                                  "
                                  >VER/LEER</vs-checkbox
                                >
                              </div>
                              <div v-if="c.createView">
                                <vs-checkbox
                                  :value="c.canWrite"
                                  @change="
                                    updateReadValue({
                                      id: c.id,
                                      value: !c.canWrite,
                                      key: 'canWrite',
                                    })
                                  "
                                  >CREAR</vs-checkbox
                                >
                              </div>
                              <div v-if="c.updateView">
                                <vs-checkbox
                                  :value="c.canUpdate"
                                  @change="
                                    updateReadValue({
                                      id: c.id,
                                      value: !c.canUpdate,
                                      key: 'canUpdate',
                                    })
                                  "
                                  >ACTUALZAR</vs-checkbox
                                >
                              </div>
                              <div v-if="c.deleteView">
                                <vs-checkbox
                                  :value="c.canDelete"
                                  @change="
                                    updateReadValue({
                                      id: c.id,
                                      value: !c.canDelete,
                                      key: 'canDelete',
                                    })
                                  "
                                  >ELIMINAR</vs-checkbox
                                >
                              </div>
                            </div>
                          </b-card>
                        </b-collapse>
                      </div>
                    </div>
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </div>
        </b-card>
      </b-collapse>
    </div>
    <hr />
    <div class="d-flex justify-content-end">
      <b-button variant="primary" @click="modal = false" class="me-2">
        {{ $t('form.abort') }}
      </b-button>
      <b-button @click="save">{{ $t('form.save') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    role: {
      type: Object,
      default: () => ({}),
      // required: true,
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: 'permissions/getHierarchyStructureBase',
      success: 'permissions/getSuccess',
    }),
  },
  methods: {
    async save() {
      const permissions = await this.flattenPermissions({
        array: this.permissions,
        roleId: this.role._id,
      });

      if (this.permissions[0]._id) {
        await this.updateMany({ permissions });
      } else {
        await this.createMany({ permissions });
      }

      if (!this.success)
        return Swal.fire('Error', 'No se pudo guardar los permisos', 'error');

      Swal.fire('Guardado', 'Permisos guardados correctamente', 'success');
      this.closeModal();
    },
    closeModal() {
      this.modal = false;
    },
    ...mapActions({
      updateReadValue: 'permissions/updateReadValue',
      flattenPermissions: 'permissions/flattenPermissions',
      createMany: 'permissions/createMany',
      updateMany: 'permissions/updateMany',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
