var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"bv-modal-permissions-hierarchy","centered":"","size":"lg","hide-footer":""},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('p',[_vm._v(" ROL: "),_c('strong',[_vm._v(_vm._s(_vm.role.name))])]),_vm._l((_vm.permissions),function(permission){return _c('div',{key:permission.id,staticClass:"mb-2"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${permission.id}`),expression:"`collapse-${permission.id}`"}],attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t(`modules.${permission.name}`))+" ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":`collapse-${permission.id}`}},[_c('b-card',[_c('div',{staticClass:"d-flex"},[(permission.readView)?_c('div',[_c('vs-checkbox',{attrs:{"value":permission.canRead},on:{"change":function($event){return _vm.updateReadValue({
                  id: permission.id,
                  value: !permission.canRead,
                  key: 'canRead',
                })}}},[_vm._v("VER/LEER")])],1):_vm._e(),(permission.createView)?_c('div',[_c('vs-checkbox',{attrs:{"value":permission.canWrite},on:{"change":function($event){return _vm.updateReadValue({
                  id: permission.id,
                  value: !permission.canWrite,
                  key: 'canWrite',
                })}}},[_vm._v("CREAR")])],1):_vm._e(),(permission.updateView)?_c('div',[_c('vs-checkbox',{attrs:{"value":permission.canUpdate},on:{"change":function($event){return _vm.updateReadValue({
                  id: permission.id,
                  value: !permission.canUpdate,
                  key: 'canUpdate',
                })}}},[_vm._v("ACTUALZAR")])],1):_vm._e(),(permission.deleteView)?_c('div',[_c('vs-checkbox',{attrs:{"value":permission.canDelete},on:{"change":function($event){return _vm.updateReadValue({
                  id: permission.id,
                  value: !permission.canDelete,
                  key: 'canDelete',
                })}}},[_vm._v("ELIMINAR")])],1):_vm._e()]),(permission.children)?_c('div',_vm._l((permission.children),function(child){return _c('div',{key:child.id,staticClass:"mb-2"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-inner-${child.id}`),expression:"`collapse-inner-${child.id}`"}]},[_vm._v(" "+_vm._s(_vm.$t(`modules.${child.name}`))+" ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":`collapse-inner-${child.id}`}},[_c('b-card',[_c('div',{staticClass:"d-flex"},[(child.readView)?_c('div',[_c('vs-checkbox',{attrs:{"value":child.canRead},on:{"change":function($event){return _vm.updateReadValue({
                          id: child.id,
                          value: !child.canRead,
                          key: 'canRead',
                        })}}},[_vm._v("VER/LEER")])],1):_vm._e(),(child.createView)?_c('div',[_c('vs-checkbox',{attrs:{"value":child.canWrite},on:{"change":function($event){return _vm.updateReadValue({
                          id: child.id,
                          value: !child.canWrite,
                          key: 'canWrite',
                        })}}},[_vm._v("CREAR")])],1):_vm._e(),(child.updateView)?_c('div',[_c('vs-checkbox',{attrs:{"value":child.canUpdate},on:{"change":function($event){return _vm.updateReadValue({
                          id: child.id,
                          value: !child.canUpdate,
                          key: 'canUpdate',
                        })}}},[_vm._v("ACTUALZAR")])],1):_vm._e(),(child.deleteView)?_c('div',[_c('vs-checkbox',{attrs:{"value":child.canDelete},on:{"change":function($event){return _vm.updateReadValue({
                          id: child.id,
                          value: !child.canDelete,
                          key: 'canDelete',
                        })}}},[_vm._v("ELIMINAR")])],1):_vm._e(),(child.children)?_c('div',_vm._l((child.children),function(c){return _c('div',{key:c.id,staticClass:"mb-2"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-inner-${c.id}`),expression:"`collapse-inner-${c.id}`"}]},[_vm._v(" "+_vm._s(_vm.$t(`modules.${c.name}`))+" ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":`collapse-inner-${c.id}`}},[_c('b-card',[_c('div',{staticClass:"d-flex"},[(c.readView)?_c('div',[_c('vs-checkbox',{attrs:{"value":c.canRead},on:{"change":function($event){return _vm.updateReadValue({
                                    id: c.id,
                                    value: !c.canRead,
                                    key: 'canRead',
                                  })}}},[_vm._v("VER/LEER")])],1):_vm._e(),(c.createView)?_c('div',[_c('vs-checkbox',{attrs:{"value":c.canWrite},on:{"change":function($event){return _vm.updateReadValue({
                                    id: c.id,
                                    value: !c.canWrite,
                                    key: 'canWrite',
                                  })}}},[_vm._v("CREAR")])],1):_vm._e(),(c.updateView)?_c('div',[_c('vs-checkbox',{attrs:{"value":c.canUpdate},on:{"change":function($event){return _vm.updateReadValue({
                                    id: c.id,
                                    value: !c.canUpdate,
                                    key: 'canUpdate',
                                  })}}},[_vm._v("ACTUALZAR")])],1):_vm._e(),(c.deleteView)?_c('div',[_c('vs-checkbox',{attrs:{"value":c.canDelete},on:{"change":function($event){return _vm.updateReadValue({
                                    id: c.id,
                                    value: !c.canDelete,
                                    key: 'canDelete',
                                  })}}},[_vm._v("ELIMINAR")])],1):_vm._e()])])],1)],1)}),0):_vm._e()])])],1)],1)}),0):_vm._e()])],1)],1)}),_c('hr'),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"me-2",attrs:{"variant":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" "+_vm._s(_vm.$t('form.abort'))+" ")]),_c('b-button',{on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('form.save')))])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }