<template>
  <b-modal
    id="bv-modal-permissions"
    size="lg"
    v-model="modal"
    @hide="closeModal"
    centered
    hide-footer
  >
    <PermissionsSelect ref="permissionsSelect" />
    <div class="d-flex justify-content-between align-items-center mb-2 w-100">
      <span> ROL: {{ role.name }} </span>
      <div class="d-flex align-items-center w-75">
        <Multiselect :options="permissionsModules" v-model="module">
          <template #singleLabel="props">
            <div class="option__desc">
              <span class="option__title">
                {{ $t(`modules.${props.option.value}`) }}
              </span>
            </div>
          </template>
          <template #option="props">
            <div class="option__desc">
              <span class="option__title">
                {{ $t(`modules.${props.option.value}`) }}
              </span>
            </div>
          </template>
        </Multiselect>
        <vs-button
          @click="addModule"
          :loading="loading"
          :disabled="!module"
          class="w-50"
        >
          AGREGAR
        </vs-button>
      </div>
    </div>
    <div class="table-responsive">
      <b-table
        :items="permissions"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
      >
        <template #cell(module)="data">
          <span>{{ $t(`modules.${data.item.module}`) }}</span>
        </template>
        <template #cell(actions)="data">
          <div class="action-btn-container">
            <b-dropdown variant="link" no-caret class="position-absolute">
              <template #button-content>
                <i class="fa fa-fw fa-bars font-size-16" />
              </template>
              <div v-if="onlyAdminRole">
                <b-dropdown-item @click="edit(data.item)">
                  <i class="uil-edit m-2 font-size-18" />
                  <span class="align-middle ml-5">EDITAR PERMISOS</span>
                </b-dropdown-item>
              </div>
              <div v-if="onlyAdminRole">
                <b-dropdown-item @click="remove(data.item)">
                  <i class="uil-trash m-2 font-size-18" />
                  <span class="align-middle ml-5">ELIMINAR</span>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PermissionsSelect from './PermissionsSelect.vue';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import { PermissionsModulesArray } from '../../../../helpers/CONSTANTS';

export default {
  name: 'Permissions',
  components: {
    PermissionsSelect,
    Multiselect,
  },
  props: {
    role: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: 'PERMISOS',
      modal: false,
      fields: [
        {
          key: 'module',
          label: 'MODULO',
        },
        {
          key: 'actions',
          label: 'ACCIONES',
        },
      ],
      perPage: 10,
      currentPage: 1,
      module: null,
      // MULTISELECT
      value: '',
      options: [
        'Select option',
        'options',
        'selected',
        'multiple',
        'label',
        'searchable',
        'clearOnSelect',
        'hideSelected',
        'maxHeight',
        'allowEmpty',
        'showLabels',
        'onChange',
        'touched',
      ],
      // MULTISELECT
      permissions: [],
      loading: false,
      permissionsModules: [...PermissionsModulesArray],
    };
  },
  computed: {
    rows() {
      return this.permissions.length;
    },
    ...mapGetters({
      onlyAdminRole: 'user/onlyAdminRole',
      success: 'permissions/getSuccess',
    }),
  },
  methods: {
    closeModal() {
      this.module = null;
      this.modal = false;
    },
    async addModule() {
      const data = {
        module: this.module.value,
        roleId: this.role._id,
      };

      this.loading = true;

      await this.create({ data });

      if (!this.success)
        Swal.fire({
          title: 'Error',
          text: 'Error al agregar modulo',
          icon: 'error',
        });
      else
        Swal.fire({
          title: 'Exito',
          text: 'Modulo agregado con exito',
          icon: 'success',
        });

      this.loading = false;
      this.closeModal();
    },
    edit(item) {
      this.$refs.permissionsSelect.value = item;
      this.$refs.permissionsSelect.options = [...this.permissions];
      this.$refs.permissionsSelect.modal = true;
    },
    async remove(item) {
      const { isConfirmed } = await Swal.fire({
        title: 'Eliminar',
        text: '¿Está seguro de eliminar este registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
      });

      if (!isConfirmed) return;

      const { _id: id } = item;

      await this.removePermission({ id });

      if (!this.success)
        return Swal.fire({
          title: 'Error al eliminar',
          text: 'Error al eliminar el permiso',
          icon: 'error',
        });

      Swal.fire({
        title: 'Eliminado',
        text: 'Permiso eliminado con exito',
        icon: 'success',
      });

      this.closeModal();
    },
    ...mapActions({
      create: 'permissions/create',
      removePermission: 'permissions/remove',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
