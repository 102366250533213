<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>NOMBRE</label>
            <vs-input
              v-model="typeform.name"
              placeholder="Nombre"
              class="shadow-lg"
              border
            />
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mt-3">
        <div class="d-flex">
          <vs-button>Guardar</vs-button>
          <vs-button @click="$emit('closeModa')" success type="button">
            Cancelar
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => ({
        name: '',
      }),
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      success: 'roles/getSuccess',
    }),
  },
  methods: {
    async save() {
      let isCreating = false;

      if (this.typeform._id) {
        const { _id: id, ...data } = this.typeform;
        await this.update({ id, data });
      } else {
        await this.create({ data: this.typeform });
        isCreating = true;
      }

      if (!this.success)
        Swal.fire({
          title: isCreating ? 'Error al crear' : 'Error al actualizar',
          text: 'Por favor, intente de nuevo',
          icon: 'error',
        });
      else
        Swal.fire({
          title: isCreating ? 'Creado' : 'Actualizado',
          text: `El registro ha sido ${
            isCreating ? 'creado' : 'actualizado'
          } con éxito`,
          icon: 'success',
        });

      return this.$emit('closeModa');
    },
    ...mapActions({
      create: 'roles/create',
      update: 'roles/update',
    }),
  },
};
</script>

<style lang="scss" scoped></style>
